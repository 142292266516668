<template>
 <div style='width: 100%; height: 100%; position: relative;'>
   
     <div class='transition' :style='getControlStyle()'>
    	<!-- CONTROLS -->
    	    
		    <div style='float: top; width: 100%; background-color: #fff; vertical-align: middle;'>
		        <div style='vertical-align: middle; text-align: center; font-size: 14pt; font-weight: bold;width: 100%;'>REPORT CREATOR 1.0</div>
		        <div style='vertical-align: middle; text-align: center; font-size: 11pt; font-weight: bold;width: 100%;'>CONTROLS</div>
		        <br/>
		        <br/>
			    <span class='SVcontrolLabel'>Channel/Station:</span><br/>
			   
			    <vSelect placeholder='choose channel' :multiple="true" :options="stations" style='width:100%' v-model="selectedStation" @input='getInventory'>
			          
			    </vSelect>
			    <br/>
			    <div>
			    <div style='display: inline-flex; width: 45%;'>
			    <InputDatePick  
                            @change="dateChanged" v-model="fromDate"/>  
                            </div>
                            -
                <div style='display: inline-flex; width: 45%;'>
                <InputDatePick  
                            @change="dateChanged" v-model="untilDate"/>     
                 </div>           

				</div>
		        <br/>
			    <span class='SVcontrolLabel'>Chart-Style: </span>
		    	<vSelect placeholder='choose chart type' :options="['bar','line']" style='width:100%' v-model="chartType" >
			          
			    </vSelect>
		    	<br/>
		    	<span class='SVcontrolLabel'>Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>
		    	<br/>
		    	
		    	
		    </div> <!-- CONTROLS -->
    </div>
    
    <!-- MAIN -->
	<div class='transition' style='float: left; width: 77%;'> 
	    <!-- HEADER -->
	    <div style='float: top; width: 100%; height: 28pt; overflow-y: scroll;'>
		
	  	</div>
	    <!-- HEADER -->
	    
	    <!-- TABLE -->
	    <div style='width: calc(100vH); height: calc(100vh - 120px); '>
	    
	    	    <D3LineChart :config='{
		  date: {
		    key: "when",
		    inputFormat: "%Y-%m-%d",
		    outputFormat: "%Y-%m-%d",
		  },
		  values: ["Placed", "Unplaced"],
		  axis: {
		    yTitle: "# of Spots",
		    xTitle: true,
		    yFormat: ".0f",
		    xFormat: "%Y-%m-%d",
		    yTicks: 5,
		    xTicks: 10
		  },
		  color: {
		    
		    current: "#1f77b4",
		    default: "#aaa",
		    axis: "#000",
		  },
		  curve: "curveMonotoneX",
		  margin: {
		    top: 5,
		    right: 20,
		    bottom: 5,
		    left: 40
		  },
		  points: {
		    visibleSize: 2,
		    hoverSize: 6,
		  },
		  tooltip: {
		    labels: true,
		  },
		  transition: {
		    duration: 500,
		    ease: "easeLinear",
		  },
		}' :datum="chartData"></D3LineChart>
	    	
	    		    {{data.labels}}
	    	{{data.seriesList}}
	    		<vuetiful-board
			    :theme="green"
			    :dark-mode="false"
			    :col-num="2"
			    :row-height="40"
			    :layout-editable="true"
			    :datasets="[
			      {
			          chartInfo: {
			            series: data.seriesList,
			            options: {
			              chart: {
			                type: chartType,
			              },
			              title: {
			                text: 'Spots per day',
			                align: 'center',
			                style: {
			                  fontSize: '18px',
			                  fontWeight: 'bold',
			                },
			              },
			             labels: data.labels,
			              fill: {
			                opacity: 1,
			              },
			              legend: {
			                position: 'bottom',
			              }
			            }
			          },
			          gridInfo: {
			            x: 6, y: 0, w: 6, h: 12, i: '1', static: true
			          },
			        },
			    ]"
			  />	

    	</div>  <!-- TABLE -->
    </div>  <!-- MAIN -->
    
 </div>
</template>

<script>
import {HTTP, fwAPI, invAPI, reportAPI, setReload, myLocale} from '@/variables.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputTimezone from '@/components/inputElements/InputTimezone';
import GridBreakView from '@/components/GridBreakView';
import InputDatePick from '@/components/inputElements/InputDatePick'; 
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { D3LineChart } from 'vue-d3-charts';
var timers = [];

export default {
  name: 'GFW Schedule View',
  components : {
   'app-switch': Switch, InputDatePick, vSelect, D3LineChart
  },
  data () {
    return {
      stationId: 0,
      station: {},
      chartType: 'bar',
      chartData: [],
      chart_config: "",
      selectedStation: {},
      currency: {},
      stations: [],
      data: [],
      fromDate: {},
      untilDate: {},
      loadingActive: false,
      controlsRight: false
    }
  },    
  methods: {
    myLocale() { return myLocale();},
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 22%; height: calc(100vh - 120px); padding: 2ex;"
    	}
    	return "float: left; width: 22%; height: calc(100vh - 120px); padding: 2ex;"
    },
    getStations() {
        let that = this;
		this.startLoader()

        let time = new Date().getTime();
    	let stationLoad = new Promise(function (resolve, reject) {
            console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/asOptions/true/0");
       		HTTP.get( fwAPI+"/getAsOptions/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/true/0")
        		 .then( response => 
                 {
                 	let dur = (new Date().getTime()-time);
               		console.log("LOADED STATIONS in " + dur +"ms");  
                    var allStations = response.data.data;
                    that.stations = [];
                    for ( var channel in allStations )
                    {
                    	if ( allStations[channel].visible )
                    	{
                    		that.stations.push( allStations[channel]);
                    	}
                    	
                    }   
                    that.selectedStation =  that.stations;
                    resolve (that.stationId);                    
                 }).catch(e => {
                        this.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
	      });
    },
    getData() {
        let that = this;
        that.data = {};
		this.startLoader();
		var channelIDs = new Array();
		for ( var channel in this.selectedStation )
        {
        	channelIDs.push( this.selectedStation[channel].id)
        }
        
		let req = {	channelIds: channelIDs, 
					fromDateISO: new Date(this.fromDate).toISOString().split('T')[0], 
					untilDateISO: new Date(this.untilDate).toISOString().split('T')[0] };
        let time = new Date().getTime();
        
    	let dataLoad = new Promise(function (resolve, reject) {
            console.log( reportAPI+"/getData/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
            
       		HTTP.post( reportAPI+"/getData/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, req)

        		 .then( response => 
                 {
                 	let dur = (new Date().getTime()-time);
               		console.log("LOADED DATA in " + dur +"ms");  
                    that.data = response.data;
                    that.chartData = new Array();
                    for ( var si in that.data.seriesList )
               		{
               			let series = that.data.seriesList[ si];
               			series.cumul = [];
               			let last = 0;
               			for ( var ii in series.data )
               			{
               			    series.cumul[ii] = series.data[ii];
               				series.cumul[ii] += last;
               				last = series.cumul[ii];
               			}
                    }
                    for ( var di in that.data.labels )
                    {
                    	let label = that.data.labels[ di];
                    	var o = new Object();
                    	o.when = label;
                    	for ( si in that.data.seriesList )
                   		{
                   			let series = that.data.seriesList[ si];
                   			
                   			o[series.name]=series.data[di];
                   		}
                   		that.chartData.push(o);
                    }
                    
                    resolve (that.stationId);                    
                 }).catch(e => {
                        this.$toast.error("loading data for report: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
	      });
    },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
    stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	}
  },
  created() {
  	this.fromDate = new Date();
  	this.untilDate = new Date();
  	this.getStations();
  },
  watch: {
  	fromDate: function() { this.getData() },
  	untilDate: function() { this.getData() },
  	selectedStation: function() { this.getData() }
  },
  updated() {
  		//console.log("update...");
	  this.$nextTick(function () {
		    // Code that will run only after the
		    // entire view has been re-rendered
		    //console.log("stopping loader");
		    if (this.loadingActive)
		    {
		        timers.push( setTimeout( this.stopLoader, 100));
		    }
		  })
 	}
}
</script>
<style>

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  font-weight: bold; 
  width: 100% !important; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #eef; 
  }
.headerDay { 
  font-size: 12pt; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc; 
  background-color: #eef; 
 }
.adMain { 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  z-Index: 17; 
  background-color: #fff; 
  margin-left: 4pt; 
  margin-right: 4pt; 
  color: #666; 
  width: 95%; 

    opacity:1;
    transition: all .5s;
   -webkit-transition: all .5s;
}

.gridMain { position: absolute; z-Index: 2; text-align: center; width: 99% ;border: 1pt solid #aaa; opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s; }
   
.SVcontrolLabel {
	font-weight: bold;
	padding-top: 9pt;
}
</style>
